'use client';
import { useEffect } from 'react';

import { useLottie } from 'lottie-react';

import assinyLoadingAnimation from '../../../public/assets/animations/assinyLoadingAnimation.json';
import * as S from './styles';

const Loading = () => {
  const options = {
    animationData: assinyLoadingAnimation,
    loop: true,
  };

  const { View, setSpeed } = useLottie(options);

  useEffect(() => {
    setSpeed(2.5);
  }, []);

  return <S.LoadingContainer>{View && <div>{View}</div>}</S.LoadingContainer>;
};

export default Loading;
